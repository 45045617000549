@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.main-container {
  padding: 120px 32px;
  padding-bottom: 20px;
  background-color: transparent;
}

.navbar, .navbar-active {
  position: fixed;
  top: 20px;
  left: 30px;
  right: 30px;
  width: calc(100% - 60px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 40px;
  padding: 15px 40px;
  /* box-shadow: 0 4px 50px rgba(0, 0, 0, 0.2); */
  z-index: 20;
}

.navbar-not-active{
  background-color: transparent;
}

.navbar-active{
  background-color: #1e1e1e;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.2)

}
.footer {
  margin-top: 20px;
  background-color: #1e1e1e;
  text-align: center;
  border-radius: 20px;
  padding: 40px 20px;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.15);
}

.footer .title {
  font-family: "Poppins";
  font-weight: 500;
  color: white;
  margin-bottom: 30px;
}

.footer p {
  font-family: "Poppins";
  font-weight: 200;
  color: white;
  font-size: 13px;
  margin-bottom: 30px;
}

.footer .rights p {
  font-family: "Poppins";
  font-weight: 200;
  color: white;
  font-size: 12px;
  margin-top: 20px;
}

.footer .icons {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer .navigation {
  padding: 0px;
  display: inline-grid;
  grid-template-columns: repeat(6, auto);
  gap: 4px;
}

.footer img {
  height: 45px;
}

.footer .navigation .item {
  width: fit-content;
  margin-left: 10px;
  margin-right: 10px;
}
@media (max-width: 1082px) {
  .footer .navigation .item {
    margin-left: 5px;
    margin-right: 5px;
  }
  .footer .title {
    margin-bottom: 15px;
  }
}
@media (max-width: 552px) {
  .footer .navigation {
    grid-template-columns: repeat(3, auto);
  }
  .footer img {
    height: 40px;
  }
  .footer .rights p {
    font-size: 11px;
  }
}
@media (max-width: 442px) {
  .footer .navigation {
    grid-template-columns: repeat(1, auto);
  }

  .footer {
    padding: 20px 10px;
  }
  .footer img {
    height: 40px;
  }
}

@media (max-width: 342px) {
  .footer img {
    height: 35px;
  }
}
.footer .navigation .gutter-row {
  margin: 0;
  text-align: center;
  color: gray;
  font-size: 12px;
  margin-bottom: 20px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.footer .icons ul {
  text-align: center;
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  color: gray;
  font-size: 12px;
  margin-bottom: 20px;
}

.footer .icons ul {
  color: #666edd;
  font-size: 12px;
  margin-bottom: 20px;
}

.footer .icons .icon-cont {
  border: 1px solid #666edd;
  border-radius: 500px;
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.footer .icons li {
  margin: 0;
  margin-left: 30px;
}

.footer .icons li {
  margin-left: 15px;
}

.footer .icons li:first-child {
  margin-left: 0;
}

.navbar-logo img {
  height: 45px;
}

.navbar .collapse-icon {
  color: white;
  -webkit-transition: color 500ms linear;
  -ms-transition: color 500ms linear;
  transition: color 500ms linear;
}

.navbar .collapse-icon:hover {
  color: #666edd;
  -webkit-transition: color 500ms linear;
  -ms-transition: color 500ms linear;
  transition: color 500ms linear;
}

.navbar .collapse {
  display: none;
  font-size: 35px;
}

.navbar-links {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin: 0;
  margin-left: 30px;
}

.navbar-links li:first-child {
  margin-left: 0;
}

.navbar-links a,
.footer a {
  text-decoration: none;
  color: #fff;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 200;
  display: flex;
  align-items: center;
}

.footer a {
  color: #666edd;
}

.navbar-links li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-links .ant-btn-default {
  background-color: #666edd !important;
  color: white !important;
  font-family: "Poppins" !important;
  border-color: #666edd !important;
  border-radius: 20px;
  padding: 8px 15px !important;
  height: auto;
}

.navbar-links .ant-btn-default:hover {
  background-color: #666eddd4 !important;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}

.navbar .nav-item {
  color: white;
  -webkit-transition: color 500ms linear;
  -ms-transition: color 500ms linear;
  transition: color 500ms linear;
  font-weight: 200;
}

.navbar .nav-item:hover {
  color: #666edd;
  -webkit-transition: color 500ms linear;
  -ms-transition: color 500ms linear;
  transition: color 500ms linear;
}

.navbar .nav-item-selected {
  color: #666edd;
  font-weight: 300;
}

.tech-icon {
  color: inherit;
  display: flex;
}

.overlay {
  height: 100%;
  position: relative;
}

.overlay .closebtn {
  display: none;
}

@media (max-width: 1082px) {
  .overlay {
    height: 0;
    width: 100%;
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
  }

  .navbar-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 25%;
    width: 100%;
    margin-bottom: 25vh;
  }

  .navbar-links li {
    margin-left: 0px;
    margin-bottom: 20px;
  }

  .navbar .collapse {
    display: flex;
  }

  .overlay .closebtn {
    display: grid;
    position: absolute;
    top: 40px;
    right: 40px;
    font-size: 30px;
    color: white;
  }

  .overlay .closebtn:hover {
    color: #666edd;
  }

  .tech-icon {
    display: none;
  }
  .navbar-links a {
    font-size: 21px;
  }
}

@media (max-width: 442px) {
  .main-container {
    padding: 95px 15px;
    padding-bottom: 15px;
  }

  .navbar {
    top: 15px;
    left: 15px;
    right: 15px;
    width: calc(100% - 30px);
    /* box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25); */
  }

  .overlay .closebtn {
    top: 20px;
    right: 20px;
  }

  .navbar-logo img {
    height: 30px;
  }

  .navbar .collapse {
    font-size: 30px;
  }

  .navbar-links a {
    font-size: 18px;
  }
}

@media (max-width: 342px) {
  .navbar {
    padding: 10px 20px;
  }

  .overlay .closebtn {
    top: 15px;
    right: 15px;
  }

  .navbar-logo img {
    height: 25px;
  }

  .navbar .collapse {
    font-size: 25px;
  }

  .navbar-links a {
    font-size: 18px;
  }
}
